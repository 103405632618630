<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">Modifier {{ apiProduct.label }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="apiProduct.label"
                    type="text"
                    label="Libellé"
                    :rules="[rules.label]"
                  ></v-text-field>
                  <v-textarea
                    v-model="apiProduct.description"
                    type="text"
                    label="Description"
                    :rules="[rules.description]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              Modifier
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  label: 'ApiProductFormDialog',

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    apiProduct: {
      id: null,
      label: null,
      code: null,
      description: null,
      max_amount: null,
      callback: null,
      is_sandbox: null,
      is_active: null,
    },
  },

  computed: {
    ...mapState('upload', ['file']),
  },

  setup(props) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const rules = {
      label: true,
      description: true,
    }
    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      message.value = ''
      Object.keys(rules).forEach(key => {
        rules[key] = true
      })
      instance.emit('clickout')
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      instance.emit('submitted')
      store.dispatch('apiProduct/updateApiProduct', props.apiProduct).then(() => {
        isDialogVisible.value = false
        close()
      }).catch(error => {
        form.value.validate()
        message.value = error.response.data.message
        errors.value = error.response.data.errors
        if (error.response.data.errors !== undefined) {
          rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
          rules.description = error.response.data.errors.description !== undefined ? error.response.data.errors.description[0] : true
        }
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
      instance.emit('submitted')
    }

    return {
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
