<template>
  <div>
    <v-card class="mb-8">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="field.keyword"
              label="Filtrer par mot clé"
              outlined
              dense
              placeholder="Filtrer par mot clé"
              hide-details="auto"
              :rules="rules.keyword"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="apiProducts"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :edit="true"
            :remove="false"
            :view="false"
            :lock="false"
            :status="false"
            :apikey="true"
            @apikey="openKeyForm(item)"
            @edit="openForm(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getApisPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getApiProducts"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <api-product-form-dialog
      :api-product="apiProductForm"
      :dialog="dialog"
      @clickout="closeDialog"
      @submitted="loading"
    />
    <api-key-form-dialog
      :dialog="keyDialog"
      @clickout="closeKeyDialog"
      @submitted="loading"
    />
  </div>
</template>

<script>
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { mapGetters, mapState } from 'vuex'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import {
  mdiAccountLock, mdiCashMinus, mdiCashPlus,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiRefresh,
  mdiSquareEditOutline, mdiKeyVariant,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import ApiKeyFormDialog from '@core/components/operator/ApiKeyFormDialog'
import ApiProductFormDialog from '@core/components/operator/ApiProductFormDialog'
import store from '@/store'
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";

export default {
  name: 'OperatorApiProductList',

  components: {ButtonsGroup, ApiKeyFormDialog, ApiProductFormDialog, LoaderDialog },

  props: {
    operator: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentPage: null,
      lastPage: null,
      dialog: false,
      keyDialog: false,
      dialogDelete: false,
      isDialogVisible: false,
      message: null,
      error: false,
      isAlertVisible: false,
      rules: {
        keyword: [true],
        api_id: [true],
        operator_id: [true],
      },
      apiProductForm: {
        id: null,
        label: null,
        code: null,
        description: null,
        api_id: null,
      },
    }
  },
  computed: {
    ...mapGetters('apiProduct', ['apiProducts', 'current_page', 'last_page', 'per_page']),
    ...mapState('apiProduct', ['field']),
  },

  created() {
    this.getApiProducts(this.current_page ? this.current_page : null)
  },

  methods: {
    loading() {
      this.isDialogVisible = !this.isDialogVisible
    },

    closeDialog() {
      this.dialog = false
    },

    closeKeyDialog() {
      this.keyDialog = false
    },

    formattingDate(date) {
      return formatDate(date)
    },

    deleteItemConfirm() {
      this.closeDelete()
    },

    openItem(item) {
      this.$router.push({ name: 'showpayment', params: { id: item.id } })
    },

    openForm(item = null) {
      this.apiProductForm = item
      this.dialog = true
    },

    openKeyForm(item) {
      store.dispatch('apiProduct/viewKey', item.id).then(() => {
        this.keyDialog = true
      }).catch(error => {
        console.log(error)
      })
    },

    closeDelete() {
      this.dialogDelete = false
    },

    getApiProducts(page = 1) {
      this.isDialogVisible = true
      this.field.operator_id = this.operator.id
      store.dispatch('apiProduct/getApisList', { page, field: this.field }).then(() => {
        this.currentPage = this.current_page
        this.lastPage = this.last_page
        this.isDialogVisible = false
        this.isAlertVisible = false
        Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        })
      }).catch(error => {
        this.message = error.response.data.message
        if (error.response.data.errors !== undefined) {
          this.rules.keyword = error.response.data.errors.keyword !== undefined ? error.response.data.errors.keyword : [true]
          this.rules.api_id = error.response.data.errors.api_id !== undefined ? error.response.data.errors.api_id : [true]
          this.rules.operator_id = error.response.data.errors.operator_id !== undefined ? error.response.data.errors.operator_id : [true]
        }
        this.error = true
        this.isAlertVisible = true
      })
    },

    // eslint-disable-next-line camelcase
    getApisPerPage(per_page) {
      // eslint-disable-next-line camelcase
      this.field.operator_id = this.operator.id
      if (per_page !== null) {
        this.isDialogVisible = true
        // eslint-disable-next-line camelcase
        this.field.paginate = per_page
        this.itemsPerPage = parseInt(per_page, 10)
        store.dispatch('apiProduct/getApisList', {
          page: this.currentPage,
          field: this.field,
        }).then(() => {
          this.currentPage = this.current_page
          this.lastPage = this.last_page
          this.isDialogVisible = false
          this.isAlertVisible = false
          Object.keys(this.rules).forEach(key => {
            this.rules[key] = [true]
          })
        })
      }
    },

    filterItems() {
      setTimeout(() => {
        this.getApisByFields()
      }, 1500)
    },

    getApisByFields() {
      this.isDialogVisible = true
      this.field.operator_id = this.operator.id
      store.dispatch('apiProduct/getApisList', {
        page: this.currentPage,
        field: this.field,
      }).then(() => {
        this.currentPage = this.current_page
        this.lastPage = this.last_page
        this.isDialogVisible = false
        this.isAlertVisible = false
        this.error = false
        this.isAlertVisible = false
        Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        })
      }).catch(error => {
        this.message = error.response.data.message
        if (error.response.data.errors !== undefined) {
          this.rules.keyword = error.response.data.errors.keyword !== undefined ? error.response.data.errors.keyword : [true]
          this.rules.api_id = error.response.data.errors.api_id !== undefined ? error.response.data.errors.api_id : [true]
          this.rules.operator_id = error.response.data.errors.operator_id !== undefined ? error.response.data.errors.operator_id : [true]
        }
        this.error = true
        this.isAlertVisible = true
      })
    },
  },

  setup() {
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    return {
      save,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'LIBELLÉ', value: 'label' },
        { text: 'CODE', value: 'code' },
        { text: 'DESCRIPTION', value: 'description' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.apiProduct.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCashMinus,
        mdiCashPlus,
        mdiKeyVariant,
      },
    }
  },
}
</script>

<style scoped>

</style>
