<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">Modifier Token</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="apiToken.value"
                    :append-icon="isKeyVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isKeyVisible ? 'text' : 'password'"
                    label="Key 1"
                    :rules="[rules.value]"
                    @click:append="isKeyVisible = !isKeyVisible"
                  ></v-text-field>
                  <v-text-field
                    v-model="apiToken.api_key"
                    :append-icon="isKeyVisible2 ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isKeyVisible2 ? 'text' : 'password'"
                    label="Key 2"
                    :rules="[rules.api_key]"
                    @click:append="isKeyVisible2 = !isKeyVisible2"
                  ></v-text-field>
                  <v-text-field
                    v-model="apiToken.reference"
                    :append-icon="isKeyVisible3 ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isKeyVisible3 ? 'text' : 'password'"
                    label="Key 2"
                    :rules="[rules.reference]"
                    @click:append="isKeyVisible3 = !isKeyVisible3"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              Modifier
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import store from '@/store'

export default {
  name: 'ApiTokenFormDialog',

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('apiToken', ['apiToken']),
  },

  setup() {
    const isKeyVisible = ref(false)
    const isKeyVisible2 = ref(false)
    const isKeyVisible3 = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const rules = {
      value: true,
      api_key: true,
      reference: true,
    }
    const instance = getCurrentInstance()

    const close = () => {
      store.dispatch('apiToken/hideKey', store.state.apiToken.apiToken.id).catch(error => {
        console.log(error)
      })
      isAlertVisible.value = false
      message.value = ''
      Object.keys(rules).forEach(key => {
        rules[key] = true
      })
      instance.emit('clickout')
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      instance.emit('submitted')
      store.dispatch('apiToken/updateKey', store.state.apiToken.apiToken).then(() => {
        isDialogVisible.value = false
        close()
      }).catch(error => {
        form.value.validate()
        message.value = error.response.data.message
        errors.value = error.response.data.errors
        if (error.response.data.errors !== undefined) {
          rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
          rules.api_key = error.response.data.errors.api_key !== undefined ? error.response.data.errors.api_key[0] : true
          rules.reference = error.response.data.errors.reference !== undefined ? error.response.data.errors.reference[0] : true
        }
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
      instance.emit('submitted')
    }

    return {
      isKeyVisible,
      isKeyVisible2,
      isKeyVisible3,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
