<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            Opérateur
          </v-card-title>
          <v-card-subtitle>Détails sur l'opérateur</v-card-subtitle>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiContactlessPayment }}
          </v-icon>
        </v-avatar>
      </div>
      <v-card-title class="justify-center flex-column">
        <v-avatar
          :color="operator.logo_path ? '' : 'primary'"
          :class="operator.logo_path ? '' : 'v-avatar-light-bg primary--text'"
          size="120"
          rounded
          class="mb-4"
        >
          <v-img
            v-if="operator.logo_path"
            :src="operator.logo_path"
            contain
          ></v-img>
          <span
            v-else-if="operator.label"
            class="font-weight-semibold text-5xl"
          >{{ avatarText(operator.label) }}</span>
        </v-avatar>
      </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiImageText }}
              </v-icon>
              Libellé:
            </span>
            <span class="text--secondary font-weight-bold">{{ operator.label }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCodeArray }}
              </v-icon>
              Code:
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="secondary"
                small
              >
                {{ operator.code }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiAccountQuestionOutline }}
              </v-icon>
              Actif:
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                :color="operator.is_active ? 'primary' : 'error'"
                small
              >
                {{ operator.is_active ? 'OUI' : 'NON' }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendar }}
              </v-icon>
              Date de création:
            </span>
            <span class="text--secondary font-weight-bold">{{ operator.created_at ? formattingDate(operator.created_at) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendarEdit }}
              </v-icon>
              Date de mise à jour:
            </span>
            <span class="text--secondary font-weight-bold">{{ operator.updated_at ? formattingDate(operator.updated_at) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendarMinus }}
              </v-icon>
              Date de suppression:
            </span>
            <span class="text--secondary font-weight-bold">{{ operator.deleted_at ? formattingDate(operator.deleted_at) : null }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-tooltip
          left
          color="info"
        >
          <template #activator="{ attr, on }">
            <v-btn
              color="info"
              outlined
              icon
              v-bind="attr"
              x-large
              v-on="on"
              @click="openForm"
            >
              <v-icon
                dark
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <operator-form-dialog
      :dialog="dialog"
      :operator="operatorForm"
      @clickout="closeDialog"
      @submitted="loading"
    />
  </div>
</template>

<script>
import { formatDate } from '@core/date/dateFunctions'
import {
  mdiBadgeAccountAlert,
  mdiBusArticulatedEnd,
  mdiBusArticulatedFront, mdiCalendar, mdiCalendarEdit, mdiCalendarMinus,
  mdiCash, mdiCashMarker,
  mdiClockStart,
  mdiImageText, mdiMapMarkerDown, mdiMapMarkerUp, mdiPencilOutline,
  mdiRoad, mdiSeatPassenger, mdiContactlessPayment, mdiCodeArray, mdiAccountQuestionOutline,
} from '@mdi/js'
import OperatorFormDialog from '@core/components/operator/OperatorFormDialog'
import { mapState } from 'vuex'

export default {
  name: 'OperatorPanel',
  components: { OperatorFormDialog },

  data() {
    return {
      dialog: false,
      isDialogVisible: false,
      operatorForm: {
        id: null,
        label: null,
        key: null,
        value: null,
      },
    }
  },

  computed: {
    ...mapState('operator', ['operator']),
  },

  methods: {
    loading() {
      this.isDialogVisible = !this.isDialogVisible
    },

    formattingDate(date) {
      return formatDate(date)
    },

    openForm() {
      this.operatorForm = this.operator
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },
  },

  setup() {
    return {
      icons: {
        mdiRoad,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
        mdiContactlessPayment,
        mdiCodeArray,
        mdiAccountQuestionOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
